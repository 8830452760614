import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Tanner Ropp | Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'My development portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, I\'m',
  name: 'Tanner',
  subtitle: 'Developer and musician.',
  cta: 'More about me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'I am a senior university student studying Computer Science, as well as Digital Arts and Sciences.',
  paragraphTwo: 'My main focus is in JAM stack development using React and React Native, but I have experience in algorithm design and back-end development as well.',
  paragraphThree: 'When I\'m not coding, I write, record, and perform music in my band, Driveaway (you can check out our Spotify with the link in the footer).  I also tutor university students in various computer science and physics courses.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'blackbook.jpg',
    title: 'Blackbook by Ruxwood',
    info: 'Blackbook is a companion app for the lifestyle brand Ruxwood, based in LA.  I built the app using React Native with Typescript, and Google Cloud Firestore for the storage and authentication.  The app, which features a brutalist design to match the Ruxwood site, allows the user to store journal entries that can be sorted and filtered in various ways.  The users data is associated with a secure account, which is further protected by a 4-digit passcode required anytime the user returns to the app.  The iOS beta is currently available on TestFlight.',
    info2: 'React Native, Typescript, Cloud Firestore',
    url: 'https://www.ruxwood.com/download-blackbook',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'spect.jpg',
    title: 'Spect App',
    info: 'I worked as a contracted full-stack developer for Spect App. Built with the MERN stack, another a small team and I designed a web app that matches undergraduate students to internship and employment opportunities based on their soft skills and other attributes.',
    info2: 'MERN stack, Heroku, Google Firebase Auth',
    url: 'http://spectapp.herokuapp.com/login',
    repo: 'https://github.com/LeaderLync/Spect-App', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'timeline.png',
    title: 'My Music Timeline',
    info: 'A web app built with React and powered by the Spotify Web API. My Music Timeline allows a user to see a comprehensive, reverse chronological feed of every release made by all the artists they follow, complete with the ability to listen to each release.',
    info2: 'React, Spotify Web API, Netlify',
    url: 'https://my-music-timeline.netlify.app/',
    repo: 'https://github.com/tanner-ropp/my-spotify-timeline', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'cluster.png',
    title: 'K-means Visualizer',
    info: 'A web app built with React that allows users to interact with the k-means clustering algorithm by setting up different data sets, varying parameters, and stepping through the algorithm visually.  Animations can be toggled and adjusted to help the user follow the clustering process.',
    info2: 'React, HTML canvas, Heroku',
    url: 'https://clustering-visualization.herokuapp.com/',
    repo: 'https://github.com/tanner-ropp/Clustering-Visualization', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'ISAIO.png',
    title: 'Kinect Music Video',
    info: 'I used a Java-based framework called Processing, along with a Kinect v1 sensor, to create the visual effects seen in this official music video for \"I\'m Scared and It\'s Okay\" by Driveaway.  The different effects were produced in real time by a custom algorithm processing the depth data receievd by the Kinect.',
    info2: 'Processing, Kinect v1 sensor',
    url: 'https://www.youtube.com/watch?v=pWrOF_RLkco',
    repo: 'https://github.com/tanner-ropp/Kinect-Processing', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Interested in my work?',
  btn: '',
  email: 'tannerwr64@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'spotify',
      url: 'https://open.spotify.com/artist/08fGtVFZ52GbLMx68J8RFF?si=Y_OLA64hR3eq3FU9gEhTuw',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tannerroppuf/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tanner-ropp',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/tanner.ropp/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
